(function ($) {
  let optModal;
  const close = function ($this, options) {
    const block = $this.closest(".win__row");
    let content = false;
    let opt = (options !== undefined ? options : ($this[0].winOptions !== undefined ? $this[0].winOption : $this[0].closest(".win__content ").winOptions));
    if (opt.container) {
      content = block.find(".win__cell > :first").length && typeof block.find(".win__cell > :first")[0] != "undefined" && typeof block.find(".win__cell > :first")[0].winOptions != "undefined" ? block.find(".win__cell > :first")[0] : false;
    } else if (opt.container === "custom") {
      content = block.find(".win__cell > :first")[0];
    } else {
      content = block.find(".win__content > :first").length && typeof block.find(".win__content > :first")[0] != "undefined" && typeof block.find(".win__content > :first")[0].winOptions != "undefined" ? block.find(".win__content > :first")[0] : false;
    }

    block.find(".win__close_top").remove();
    if (content && typeof content.winOptions.beforeClose == "function") {
      content.winOptions.beforeClose(block);
    }

    if (opt.cache) {
      if (!$(".win__hidden").length) $("body").append($("<div class=\"win__hidden pb-hidden\" style=\"display: none;\"></div>"));
      if (opt.container === true) {
        if (opt.container === true || opt.container === "custom") {
          block.find(".win__content > *").appendTo($(".win__hidden"));
        } else {
          block.find(".win__cell > *").appendTo($(".win__hidden"));
        }
      } else {
        if (opt.container === true || opt.container === "custom") {
          block.find(".win__cell > *").appendTo($(".win__hidden"));
        } else {
          block.find(".win__content > *").appendTo($(".win__hidden"));
        }
      }
    }
    setTimeout(function () {
      block.remove();
      bg("close");
    }, 10);
  };
  const bg = function (action) {
    if (action === "close") {
      if (!$(".win > div").length) {
        $(".win").remove();
        $("body").removeClass("win-show");
      }
    } else if (!$(".win").length) {
      $("<div class=\"win\">").appendTo("body");

      setTimeout(function () {
        $("body").addClass("win-show");
      }, 100);
    }
  };
  const checkScrollBars = function () {
    let $html = $(document),
      $window = $(window),
      normalw = 0,
      scrollw = 0;
    if ($html.height() > $window.height()) {
      normalw = window.innerWidth;
      scrollw = normalw - $html.width();
      document.documentElement.style.setProperty("--scroll-bar", `${scrollw}px`);
    }
  };
  const methods = {
    init: function (options) {
      checkScrollBars();
      this.unbind("_win");
      return this.each(function () {
        options = $.extend({
          cache: false,
          afterOpen: false,
          beforeClose: false,
          closeButton: true,
          bgClose: true,
          bgColor: "",
          swipeClose: false,
          container: false,
          cssreset: true,
          class: ""
        }, options);
        bg();
        let _win = {};
        _win.win = $(this).removeClass("hide");
        _win.win[0].winOptions = options;
        if (options.container && options.container !== "custom") {
          _win.html = $("<div class=\"win__row\"><div class=\"win__cell\"><div class=\"win__content " + options.class + "\"></div></div></div>");
          _win.html.find(".win__content").append(_win.win);
        } else {
          _win.html = $("<div class=\"win__row\"><div class=\"win__cell " + options.class + "\"></div></div>");
          _win.html.find(".win__cell").append(_win.win);
        }

        _win.html.prependTo(".win");
        _win.win.show();
        if (options.closeButton) {
          _win.win.append($("<span class=\"win__close win__close_top\"></span>"));
          _win.html.find(".win__close").off().on("click", function (e) {
            if ($(this).attr("href") === "#") {
              e.preventDefault();
            }
            if ($(this).closest(".win__content")[0] !== undefined) {
              close($(this), $(this).closest(".win__content").children()[0].winOptions);
            } else if ($(this).closest(".win__cell > .win__content")[0] === undefined) {
              close($(this), $(this).closest(".win__cell").children()[0].winOptions);
            } else {
              close($(this), options);
            }
          });
        }
        if (typeof options.afterOpen == "function") {
          options.afterOpen();
        }
        if (options.cssreset) {
          $(".win").addClass("css__reset");
        }
        if (options.bgClose) {
          if (!$(".win").hasClass("init").length) {
            $(".win").addClass("init").on("mousedown", function (e) {
              if ($(e.target).hasClass("win__row") && !$(e.target).closest(".win__row").hasClass("loading")) {
                if ($(e.target).find(".win__content")[0].winOptions) {
                  close($(e.target), $(e.target).find(".win__content")[0].winOptions);
                } else if ($(e.target).find(".win__cell").children()[0].winOptions) {
                  close($(e.target), $(e.target).find(".win__cell").children()[0].winOptions);
                } else {
                  close($(e.target), $(e.target).find(".win__content").children()[0].winOptions);
                }
              }
            });
          }
        }
        if (options.bgColor != "") {
          $(".win__content").css("background-color", options.bgColor);
        }
        optModal = options;
      });
    },
    close: function () {
      const $this = this;
      setTimeout(function () {
        $this.each(function () {
          if ($(this).closest(".win__content")[0] !== undefined) {
            close($(this), $(this).closest(".win__content").children()[0].winOptions);
          } else if ($(this).closest(".win__cell > .win__content")[0] === undefined) {
            close($(this), $(this).closest(".win__cell").children()[0].winOptions);
          } else {
            close($(this), optModal);

          }
        });
      }, 100);
    }
  };
  $.fn._win = function (method, options) {
    if (methods[method]) {
      return methods[method].apply(this, Array.prototype.slice.call(arguments, 1), options);
    } else if (typeof method === "object" || !method) {
      return methods.init.apply(this, arguments);
    } else {
      $.error("Метод " + method + " не существует в jQuery._win");
    }
  };
})(jQuery);